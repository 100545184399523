import { MarketCountryCode } from '@backmarket/http-api'

export const MODAL_NAMES = {
  CART_INSURANCE_OFFER_CATCHUP: 'CART_INSURANCE_OFFER_CATCHUP',
  CART_INSURANCE_OFFER_EXPLANATIONS: 'CART_INSURANCE_OFFER_EXPLANATIONS',
  CART_COLLECTION_POINTS: 'CART_COLLECTION_POINTS',
  CART_WARRANTY: 'WARRANTY_CART',
  SUMMARY_SERVICE_FEE: 'SUMMARY_SERVICE_FEE',
}

export const TOAST = {
  CART_COLLECTION_POINTS_SUBMIT_ERROR: 'CART_COLLECTION_POINTS_SUBMIT_ERROR',
  TOAST_CHECKOUT_ADDRESS_SWAP: 'TOAST_CHECKOUT_ADDRESS_SWAP',
}

export const DEBOUNCE_DELAY = 250

export const SHIPPING_ALERTS = {
  /**
   * Due to the Covid-19 crisis, carriers are not delivering to certain areas.
   */
  COVID19: 'covid19',
  FLOODING: 'FLOODING',
  PAYMENT_DOWN: 'payment_down',
}

export const DISPLAY_LEARN_MORE_LINK = {
  [MarketCountryCode.SE]: false,
  [MarketCountryCode.SK]: false,
  [MarketCountryCode.GR]: false,
  [MarketCountryCode.IE]: false,
  [MarketCountryCode.AU]: false,
  [MarketCountryCode.JP]: false,
}

export const CHECKOUT_LOG_TYPES = {
  ADDRESS_AUTOCOMPLETE: 'CHECKOUT_ADDRESS_AUTOCOMPLETE',
  ADDRESS_SUBMIT_SHIPPING: 'CHECKOUT_ADDRESS_SUBMIT_SHIPPING',
  ADDRESS_SUBMIT_BILLING: 'CHECKOUT_ADDRESS_SUBMIT_BILLING',
  ADDRESS_SUBMIT_CONFIRMATION: 'CHECKOUT_ADDRESS_SUBMIT_CONFIRMATION',
  ADDRESS_SUBMIT_COLLECTION_POINT: 'CHECKOUT_ADDRESS_SUBMIT_COLLECTION_POINT',
}
