<template>
  <div>
    <RevInputDate
      :id="id"
      v-bind="$attrs"
      v-model="date"
      autocomplete="bday"
      data-cs-mask
      :label="i18n(translations.label)"
    />

    <div class="body-2 mt-4">
      {{ i18n(translations.information) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInputDate } from '@ds/components/InputDate'

import translations from './InputAddressBirthdate.translations'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    id?: string
    value: string
  }>(),
  {
    id: 'birthdate',
  },
)

const i18n = useI18n()

const date = ref(props.value ?? '')
</script>
