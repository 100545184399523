import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isAgeLegal } from '@backmarket/utils/date/isAgeLegal'
import { FORM_VALID, required } from '@ds/components/Form'

import translations from '../components/UserInformation/InputAddressBirthdate/InputAddressBirthdate.translations'
import type { ValidatorWithI18n } from '../types'

const MIN_AGE = 18
const MIN_YEAR = 1901

type AddressBirthdateValues = {
  [x: string | number | symbol]: unknown
}

const ageValidation: ValidatorWithI18n<AddressBirthdateValues> =
  (i18n: ReturnType<typeof useI18n>) => (date) => {
    const birth = new Date(date as string)
    const isYoungEnough = birth.getFullYear() > MIN_YEAR
    // /!\ isAgeLegal mutates birth
    const isOldEnough = isAgeLegal({ legalAge: MIN_AGE, birthdate: birth })

    return isOldEnough && isYoungEnough
      ? FORM_VALID
      : i18n(translations.legalAgeError)
  }

export const InputAddressBirthdateValidators = (
  i18n: ReturnType<typeof useI18n>,
) => ({
  required: required(i18n(translations.required)),
  isLegalAge: ageValidation(i18n),
})
