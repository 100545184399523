import { nationalIdTranslations } from '../translations'
import { NationalIdCountries } from '../types'

export const NATIONAL_ID_FORMATS = {
  [NationalIdCountries.ES]: {
    regexp:
      /(([XYZxyz]){1}([-]?)((\d){7})([-]?)([A-Za-z]{1}))|((\d{8})([-]?)([A-Za-z]))|(([ABNWabnw]){1}([-]?)((\d){8}))/,
    length: 9,
  },
  [NationalIdCountries.IT]: {
    regexp:
      /[A-Za-z ]{6,7}[0-9]{2}[A-Za-z]{1}[0-9]{2}[ ]?[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}/,
    length: 16,
  },
  [NationalIdCountries.PT]: {
    regexp: /\d{9}/,
    length: 9,
  },
}

export const NATIONAL_ID_TRANSLATIONS = {
  [NationalIdCountries.ES]: {
    label: nationalIdTranslations.esLabel,
    tooLong: nationalIdTranslations.esTooLong,
    format: nationalIdTranslations.esFormat,
  },
  [NationalIdCountries.IT]: {
    label: nationalIdTranslations.itLabel,
    tooLong: nationalIdTranslations.itTooLong,
    format: nationalIdTranslations.itFormat,
  },
  [NationalIdCountries.PT]: {
    label: nationalIdTranslations.ptLabel,
    tooLong: nationalIdTranslations.ptTooLong,
    format: nationalIdTranslations.ptFormat,
  },
}

export const COUNTRIES_WITH_NATIONAL_ID = Object.keys(NATIONAL_ID_TRANSLATIONS)

export const COUNTRIES_REQUIRING_NATIONAL_ID: NationalIdCountries[] = []
