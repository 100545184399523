import { MarketCountryCode } from '@backmarket/http-api'

export const GOOD_DEED_INFORMATION_2: Record<string, boolean> = {
  [MarketCountryCode.US]: false,
  [MarketCountryCode.GB]: false,
  [MarketCountryCode.JP]: false,
  default: true,
}

export const COUNTRIES_WITHOUT_BENEFIT_3: Record<string, boolean> = {
  default: false,
}
